<template>
  <!--component_header-->
  <Logout> </Logout>
  <header id="g_header" v-if="phone">
    <div id="gauser"></div>
    <div class="layer">
      <h1>
        <router-link :to="{ name: 'Front top' }">
          <img
            src="@/assets/front_component/images/logo/logo01.png"
            alt="めぐるーむ"
          />
        </router-link>
      </h1>
      <span class="menu-trigger" href="javascript:void(0)" id="button">
        <span></span>
        <span></span>
        <span></span>
      </span>
      <div class="logo_wrap">
        <img
          src="@/assets/front_component/images/logo/logo02.png"
          alt="TAKAYAMA"
        />
      </div>
    </div>
    <nav class="g_header_nav">
      <ul class="g_header_list">
        <li class="g_header_item">
          <div class="profile_wrap">
            <div class="user_wrap">
              <div class="left_area">
                <router-link :to="{ name: 'Front user edit' }">
                  <div
                    class="user_img"
                    :style="{ backgroundImage: 'url(' + profileImg + ')' }"
                    v-if="profileImg"
                  ></div>
                  <div
                    class="user_img"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/front_component/images/test/test01.png') +
                        ')',
                    }"
                    v-else
                  ></div>
                </router-link>
              </div>
              <div class="right_area">
                <div class="user_name" v-if="$User">
                  {{ $User.last_name }} {{ $User.first_name }}
                </div>
                <div
                  class="director_name"
                  v-if="$User.group && $User.group.parent_name"
                >
                  {{ $User.group.parent_name }}
                </div>
                <div class="director_name" v-else-if="$User.group">
                  {{ $User.group.name }}
                </div>
              </div>
            </div>
            <ul class="awards_link">
              <li class="awards_item" v-for="num of awardTenNum" :key="num">
                <img
                  src="@/assets/front_component/images/assets/awards01.png"
                  alt="10個"
                />
              </li>
              <li class="awards_item" v-for="num of awardOneNum" :key="num">
                <img
                  src="@/assets/front_component/images/assets/awards02.png"
                  alt="1個"
                />
              </li>
            </ul>
            <div class="reaction_wrap">
              <div class="reaction_item">
                <span>サンクス/ホメロン</span>
                <router-link
                  :to="{ hash: '#target_area', name: 'Front user edit' }"
                  >{{ thanksNum }}</router-link
                >
              </div>
            </div>
            <router-link
              :to="{ name: 'Front user edit' }"
              v-if="notifiationNumForThanks > 0"
              class="notifiation_num"
              >サンクス未読：<span>{{ notifiationNumForThanks }}</span
              >件</router-link
            >
          </div>
        </li>
        <li class="g_header_item">
          <router-link :to="{ name: 'Front top' }">トップ</router-link>
        </li>
        <li class="g_header_item has_child">
          <span>企業ページ</span>
          <div class="menu_outwrap" style="display: none">
            <div class="menu_inwrap">
              <ul class="g_nav_mega_list">
                <li class="g_nav_mega_list_item">
                  <router-link :to="{ name: 'Front business' }"
                    >社是・綱領</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link :to="{ name: 'Front ceo' }"
                    >社長メッセージ</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link :to="{ name: 'Front policy' }"
                    >会社方針・部門方針</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- <li class="g_header_item">
          <router-link
            :to="{ name: 'Front board archive', params: { type: 'board' } }"
            >掲示板</router-link
          >
        </li> -->
        <li class="g_header_item">
          <router-link :to="{ name: 'Front staff' }"
            >役員・社員紹介</router-link
          >
        </li>
        <li class="g_header_item has_child">
          <div v-if="notifiationNumForDaily > 0" class="notifiation_icon"></div>
          <span>公開記事</span>
          <div class="menu_outwrap" style="display: none">
            <div class="menu_inwrap">
              <ul class="g_nav_mega_list">
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Front archive', params: { type: 'news' } }"
                    >お知らせ一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{
                      name: 'Front event archive',
                      params: { type: 'event' },
                    }"
                    >イベント一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Front archive', params: { type: 'column' } }"
                    >コラム一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Front archive', params: { type: 'award' } }"
                    >表彰一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Front archive', params: { type: 'idea' } }"
                    >創発一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Front archive', params: { type: 'sdgs' } }"
                    >SDGs個人目標</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{
                      name: 'Front archive',
                      params: { type: 'challenge' },
                    }"
                    >チャレンジシート</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Front archive', params: { type: 'daily' } }"
                    >気づき日報一覧 
                    <div
                      v-if="notifiationNumForDaily > 0"
                      class="notifiation_num"
                    >
                      未読：<span>{{ notifiationNumForDaily }}</span
                      >件
                    </div></router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link :to="{ name: 'Front photolog' }"
                    >フォトログ一覧</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="g_header_item has_child">
          <span>My投稿</span>
          <div class="menu_outwrap" style="display: none">
            <div class="menu_inwrap">
              <ul class="g_nav_mega_list">
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Control message', params: { type: 'news' } }"
                    >My お知らせ一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link :to="{ name: 'Control event' }"
                    >My イベント一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{
                      name: 'Control message',
                      params: { type: 'column' },
                    }"
                    >My コラム一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{
                      name: 'Control message',
                      params: { type: 'thanks' },
                    }"
                    >My サンクスカード一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Control message', params: { type: 'idea' } }"
                    >My 創発一覧</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Control message', params: { type: 'sdgs' } }"
                    >My SDGs個人目標</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{
                      name: 'Control message',
                      params: { type: 'photolog' },
                    }"
                    >My フォトログ</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{
                      name: 'Control message',
                      params: { type: 'challenge' },
                    }"
                    >My チャレンジシート</router-link
                  >
                </li>
                <li class="g_nav_mega_list_item">
                  <router-link
                    :to="{ name: 'Control message', params: { type: 'daily' } }"
                    >My 気づき日報</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="g_header_item">
          <router-link :to="{ name: 'Front user edit' }"
            >ユーザープロフィール
                <router-link to="/mypage/user#thanks-heading">
                  <div v-if="notifiationNumForThanks > 0" class="notifiation_num">
                    サンクス未読：<span>{{ notifiationNumForThanks }}</span
                    >件
                  </div>
                </router-link>
            </router-link
             >
        </li>
        <li class="g_header_item">
          <router-link :to="{ name: 'Front approval' }"
            >承認依頼：<span>{{ approvalNum }}</span>件</router-link
          >
        </li>
        <li class="g_header_item">
          <router-link :to="{ name: 'Reject' }"
            >承認/却下コメント一覧</router-link
          >
        </li>
        <li class="g_header_item">
          <router-link :to="{ name: 'Dashboard' }">管理画面</router-link>
        </li>
        <li class="g_header_item logout">
          <a @click="logout()" href="javascript:void(0)">ログアウト</a>
        </li>
      </ul>
    </nav>
  </header>
  <header id="g_header" v-else>
    <div id="gauser"></div>
     <div class="content_wrap">
      <div class="left_area">
        <h1 class="logo_wrap">
          <router-link :to="{ name: 'Front top' }"
            ><img
              src="@/assets/front_component/images/logo/logo01.png"
              alt="めぐるーむ"
          /></router-link>
        </h1>
        <!--
        <div class="user_wrap">
          <router-link to="/mypage/user/">
            <div
              class="user_img"
              :style="{ backgroundImage: 'url(' + profileImg + ')' }"
              v-if="profileImg"
            ></div>
            <div
              class="user_img"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/front_component/images/test/test01.png') +
                  ')',
              }"
              v-else
            ></div>
            <div class="user_name" v-if="$User">
              {{ $User.last_name }} {{ $User.first_name }}
            </div>
            <ul class="awards_link">
              <li class="awards_item" v-for="num of awardTenNum" :key="num">
                <img
                  src="@/assets/front_component/images/assets/awards01.png"
                  alt="10個"
                />
              </li>
              <li class="awards_item" v-for="num of awardOneNum" :key="num">
                <img
                  src="@/assets/front_component/images/assets/awards02.png"
                  alt="1個"
                />
              </li>
            </ul>
          </router-link>
        </div>
    -->
      </div>
      <div class="right_area">
        <nav class="g_header_nav">
          <ul class="g_header_list">
            <li class="g_header_item">
              <router-link :to="{ name: 'Front top' }">トップ</router-link>
            </li>
            <li class="g_header_item has_child company">
              <a href="javascript:void(0)">企業ページ</a>
              <div class="menu_outwrap">
                <div class="menu_inwrap">
                  <div class="menu_ttl_wrap">
                    <div class="menu_ttl">
                      <div class="menu_ttl_text">
                        <span class="en">COMPANY</span
                        ><span class="jp">企業ページ</span>
                      </div>
                    </div>
                  </div>
                  <ul class="g_nav_mega_list">
                    <li class="g_nav_mega_list_item">
                      <router-link :to="{ name: 'Front business' }"
                        >社是・綱領</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link :to="{ name: 'Front ceo' }"
                        >社長メッセージ</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link :to="{ name: 'Front policy' }"
                        >会社方針・部門方針</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- <li class="g_header_item board">
              <router-link
                :to="{ name: 'Front board archive', params: { type: 'board' } }"
                >掲示板</router-link
              >
            </li> -->
            <li class="g_header_item staff">
              <router-link :to="{ name: 'Front staff' }"
                >役員・社員紹介</router-link
              >
            </li>
            <li class="g_header_item has_child card">
              <div
                v-if="notifiationNumForDaily > 0"
                class="notifiation_icon"
              ></div>
              <a href="javascript:void(0)">公開記事</a>
              <div class="menu_outwrap">
                <div class="menu_inwrap">
                  <div class="menu_ttl_wrap">
                    <div class="menu_ttl">
                      <div class="menu_ttl_text">
                        <span class="en">CARD INDEX</span
                        ><span class="jp">公開記事</span>
                      </div>
                    </div>
                  </div>
                  <ul class="g_nav_mega_list">
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'news' },
                        }"
                        >お知らせ一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front event archive',
                          params: { type: 'event' },
                        }"
                        >イベント一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'column' },
                        }"
                        >コラム一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'award' },
                        }"
                        >表彰一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'idea' },
                        }"
                        >創発一覧</router-link
                      >
                    </li>
                    <!-- <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'sdgs' },
                        }"
                        >SDGs個人目標</router-link
                      >
                    </li> -->
                    <!-- <li class="g_nav_mega_list_item" >
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'challenge' },
                        }"
                        >チャレンジシート</router-link
                      >
                    </li> -->
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Front archive',
                          params: { type: 'daily' },
                        }"
                      >
                        気づき日報一覧
                        <div
                          v-if="notifiationNumForDaily > 0"
                          class="notifiation_num"
                        >
                          未読：<span>{{ notifiationNumForDaily }}</span
                          >件
                        </div>
                      </router-link>
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link :to="{ name: 'Front photolog' }"
                        >フォトログ一覧</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="g_header_item has_child mypage">
              <a href="javascript:void(0)">My投稿</a>
              <div class="menu_outwrap">
                <div class="menu_inwrap">
                  <div class="menu_ttl_wrap">
                    <div class="menu_ttl">
                      <div class="menu_ttl_text">
                        <span class="en">MYPAGE</span
                        ><span class="jp">My投稿</span>
                      </div>
                    </div>
                  </div>
                  <ul class="g_nav_mega_list">
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'news' },
                        }"
                        >My お知らせ一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link :to="{ name: 'Control event' }"
                        >My イベント一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'column' },
                        }"
                        >My コラム一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'thanks' },
                        }"
                        >My サンクスカード一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'idea' },
                        }"
                        >My 創発一覧</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'sdgs' },
                        }"
                        >My SDGs個人目標</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'photolog' },
                        }"
                        >My フォトログ</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'challenge' },
                        }"
                        >My チャレンジシート</router-link
                      >
                    </li>
                    <li class="g_nav_mega_list_item">
                      <router-link
                        :to="{
                          name: 'Control message',
                          params: { type: 'daily' },
                        }"
                        >My 気づき日報</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="g_header_item icon">
              <div
                v-if="notifiationNumForThanks > 0"
                class="notifiation_icon"
              ></div>
              <div class="user_img_wrap">
                <div
                  class="user_img"
                  :style="{ backgroundImage: 'url(' + profileImg + ')' }"
                  v-if="profileImg"
                >
                  <ul
                    style="display: none"
                    class="awards_link"
                    v-if="awardTenNum < 1 && awardOneNum > 0 && awardOneNum < 5"
                  >
                    <li
                      class="awards_item"
                      v-for="num of awardOneNum"
                      :key="num"
                      :style="{ zIndex: 4 - num }"
                    >
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                  </ul>
                  <ul
                    class="awards_link"
                    v-else
                    style="left: -3px; display: none"
                  >
                    <li class="awards_item" style="z-index: 3">
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                    <li class="awards_item" style="z-index: 2">
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                    <li class="awards_item" style="z-index: 1">
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                    <li class="awards_item" style="z-index: 0">
                      <img
                        src="@/assets/front_component/images/assets/awards03.png"
                        alt="1個"
                      />
                    </li>
                  </ul>
                </div>
                <div
                  class="user_img"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/front_component/images/test/test01.png') +
                      ')',
                  }"
                  v-else
                >
                  <ul
                    style="display: none"
                    class="awards_link"
                    v-if="awardTenNum < 1 && awardOneNum > 0 && awardOneNum < 5"
                  >
                    <li
                      class="awards_item"
                      v-for="num of awardOneNum"
                      :key="num"
                      :style="{ zIndex: 4 - num }"
                    >
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                  </ul>
                  <ul
                    class="awards_link"
                    v-else
                    style="left: -3px; display: none"
                  >
                    <li class="awards_item" style="z-index: 3">
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                    <li class="awards_item" style="z-index: 2">
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                    <li class="awards_item" style="z-index: 1">
                      <img
                        src="@/assets/front_component/images/assets/awards02.png"
                        alt="1個"
                      />
                    </li>
                    <li class="awards_item" style="z-index: 0">
                      <img
                        src="@/assets/front_component/images/assets/awards03.png"
                        alt="1個"
                      />
                    </li>
                  </ul>
                </div>

                <div class="user_ctl">
                  <div class="outwrap">
                    <div class="wrap clearfix">
                      <div class="f_left">
                        <div
                          class="user_img"
                          :style="{
                            backgroundImage: 'url(' + profileImg + ')',
                          }"
                          v-if="profileImg"
                        ></div>
                        <div
                          class="user_img"
                          :style="{
                            backgroundImage:
                              'url(' +
                              require('@/assets/front_component/images/test/test01.png') +
                              ')',
                          }"
                          v-else
                        ></div>
                      </div>
                      <div class="f_right">
                        <div class="user_name" v-if="$User">
                          {{ $User.last_name }} {{ $User.first_name }}
                        </div>
                        <div
                          class="director_name"
                          v-if="$User.group && $User.group.parent_name"
                        >
                          {{ $User.group.parent_name }}
                        </div>
                        <div class="director_name" v-else-if="$User.group">
                          {{ $User.group.name }}
                        </div>
                      </div>
                    </div>
                    <ul
                      class="awards_link"
                      v-if="awardTenNum > 0 || awardOneNum > 0"
                    >
                      <li
                        class="awards_item"
                        v-for="num of awardTenNum"
                        :key="num"
                      >
                        <img
                          src="@/assets/front_component/images/assets/awards01.png"
                          alt="10個"
                        />
                      </li>
                      <li
                        class="awards_item"
                        v-for="num of awardOneNum"
                        :key="num"
                      >
                        <img
                          src="@/assets/front_component/images/assets/awards02.png"
                          alt="1個"
                        />
                      </li>
                    </ul>
                  </div>
                  <nav>
                    <ul>
                      <li>
                        <div class="user-profile-link" style="display: flex; align-items: center;">
                          <div> 
                            <router-link to="/mypage/user">
                              ユーザープロフィール
                            </router-link>
                          </div>
                          <div
                            v-if="notifiationNumForThanks > 0"
                           >
                              <router-link to="/mypage/user#thanks-heading" class="notification_num_thanks_redirect">
                                サンクス未読：<span>{{ notifiationNumForThanks }}</span
                                  >件
                              </router-link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'Front approval' }"
                          class="application"
                          v-if="approvalNum > 0"
                          >承認依頼：<span
                            >{{ approvalNum }}</span
                          > 件</router-link
                        >
                        <router-link :to="{ name: 'Front approval' }" v-else
                          >承認依頼：<span
                            >{{ approvalNum }}</span
                          > 件</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'Reject' }"
                          >承認/却下コメント一覧</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'Dashboard' }"
                          >管理画面</router-link
                        >
                      </li>
                      <li>
                        <a @click="logout()" href="javascript:void(0)"
                          >ログアウト</a
                        >
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </li>
            <!--
            <li class="g_header_item logout">
              <a @click="logout()" href="javascript:void(0)">ログアウト</a>
            </li>
        -->
          </ul>
        </nav>
      </div>
    </div>
    <!-- .content_wrap  -->
  </header>
  <!-- #g_header  -->
  <!--/component_header-->
</template>

<script>
import Api from "@/apis/Api";
import User from "@/apis/User";
import $ from "jquery";
import message from "@/apis/Message";
import Notification from "@/apis/Notification";
import Logout from "../../components/mypage_panel/Logout.vue";
import Condition from "yup/lib/Condition";

export default {
  name: "Header",
  components: {
    Logout,
  },
  data() {
    return {
      phone: "",
      profileImg: false,

      awardOneNum: "",
      awardTenNum: 0,
      thanksNum: 0,
      approvalNum: "",
      notifiationNumForThanks: "",
      notifiationNumForDaily: "",
    };
  },
  created() {
    this.phoneMatch();
    this.notificationCountForThanks();
    this.notificationCountForDaily();
    this.profileImgApi();
    this.awardCount("award", this.$User.id);
    this.dailyNoti("daily", this.$User.id);
    this.thanksCount("thanks", this.$User.id);
    this.approvalCount(this.$User.id);
    
  },
  mounted() {
    this.$nextTick(function () {
      $(function () {
        console.log(location.href);
        console.log(location.pathname);
        if (location.href.indexOf("company") !== -1) {
          $(".g_header_item.company").addClass("current");
        } else if (location.href.indexOf("board") !== -1) {
          $(".g_header_item.board").addClass("current");
        } else if (location.href.indexOf("staff") !== -1) {
          $(".g_header_item.staff").addClass("current");
        } else if (location.href.indexOf("mypage/user") !== -1) {
          console.log(location.pathname);
        } else if (location.href.indexOf("mypage") !== -1) {
          $(".g_header_item.mypage").addClass("current");
        } else if (location.href.indexOf("approval") !== -1) {
          console.log(location.pathname);
        } else if (location.href.indexOf("approve-reject") !== -1) {
          $(".g_header_item.mypage").addClass("current");
        } else if (location.pathname == "/") {
          console.log(location.pathname);
        } else {
          $(".g_header_item.card").addClass("current");
        }
        $(document).on("click", function (e) {
          // ターゲット要素の外側をクリックした時の操作
          if (!$(e.target).closest(".g_header_item > .user_img_wrap").length) {
            $(".user_ctl").removeClass("ctl_open");
            // ターゲット要素をクリックした時の操作
          } else {
            $(".user_ctl").addClass("ctl_open");
          }
        });
      });
    });
    //ユーザーIDを設定
      if (this.$User) {
        var setUser = this.$User;
        var s = document.createElement("script");
        s.textContent = `window.dataLayer = window.dataLayer || [];
        dataLayer.push({
        'user_id': '` + setUser.id +`',
        'group_id': '` + setUser.group.id +`',
        'user_name': '` + setUser.last_name + setUser.first_name +`',
        'user_code': '` + setUser.user_code +`'
      });`;
      var ele = document.getElementById("gauser");
      ele.appendChild(s);
      }
      var ga = document.createElement("script");
      ga.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TZ4HXQQ');`;
      var element = document.getElementById("gauser");
      element.appendChild(ga);

      var ganoscript = document.createElement("noscript");
      ganoscript.textContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZ4HXQQ"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      var element3 = document.getElementById("gauser");
      element3.appendChild(ganoscript);

  },
  methods: {
    phoneMatch: function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        this.phone = true;
        document.body.id = "sp_style";
      } else {
        this.phone = false;
        document.body.id = "pc_style";
      }
    },
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    awardCount(type, user) {
      this.loader = true;

      message
        .listNumCount(type, user)
        .then((response) => {
          if (response != null) {
            //console.log(response.data.data.length);
            var str = String(response.data.data.length);
            //console.log(str.length);
            if (str.length > 1) {
              var footSliceOne = str.slice(-1);
              var numOne = Number(footSliceOne);
              this.awardOneNum = numOne;
              var footSliceTen = str.slice(0, -1);
              var numTen = Number(footSliceTen);
              this.awardTenNum = numTen;
            } else {
              this.awardOneNum = response.data.data.length;
            }

            //console.log(this.awardOneNum);
            //console.log(this.awardTenNum);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    thanksCount(type, user) {
      this.loader = true;

      message
        .listNumCount(type, user)
        .then((response) => {
          if (response != null) {
            this.thanksNum = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    approvalCount(user) {
      message
        .listNumCountApproval(user)
        .then((response) => {
          if (response != null) {
            this.approvalNum = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    logout() {
      let router = this.$router;
      $("#logOutConfirm").addClass("open");
      $("#logout-btn").click(function () {
        $("#logOutConfirm").removeClass("open");
        User.logout()
          .then((response) => {
            router.push({ name: "Control Login" });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },
    notificationCountForThanks() {
      Notification.thanksNotificationCount("thanks")
        .then(
          (response) => {
            if (response != null) {
              this.notifiationNumForThanks = response.data.data;
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    notificationCountForDaily() {
      Notification.notificationCount("daily")
        .then(
          (response) => {
            if (response != null) {
              this.notifiationNumForDaily = response.data.data;
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    dailyNoti(type, user) {
      message
        .listNumCount(type, user)
        .then((resp) => {
          console.log(["header resp", resp.data.data.length]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
.notification_num_thanks_redirect {
  display: inline-block;
  font-size: 0.9rem !important;
  background-color: #e75839;
  color: #fff!important;
  min-width: 70px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px #fff solid;
  padding: 2px 12px !important;
}
.notification_num_thanks_redirect:hover{
  background-color: #e75839 !important;
  color: #fff!important;
}
.notification_num_thanks_redirect span {
  font-size: 1rem;
}
.user-profile-link:hover{
  background-color: #e6f0f6;
}

</style>
