<template>
  <Header></Header>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
      <div class="mv_cloud">
        <div class="right_area">
          <div class="img04"><img src="@/assets/front_component/images/assets/back02.png" alt="雲"></div>
        </div>
        <div class="left_area">
          <div class="img01"><img src="@/assets/front_component/images/assets/back01.png" alt="雲"></div>
        </div>
      </div>
      <div class="en_ttl en"><span>RELEASE</span></div>
      <div class="content_wrap">
        <h2>更新情報</h2>
        <div class="mascot_wrap">
          <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君">
        </div>
      </div>
    </div>
    <main>
      <div class="section_all_wrap">
        <div class="content_in_wrap">
          <div id="breadcrumb">
            <div class="btn_wide blue">
              <router-link :to="{ name: 'Control Login' }">ログイン画面へもどる</router-link>
            </div>
          </div>
          <div class="content" v-if="phone">
            <div class="innar">
              <article class="post_area">
                <div class="date t_r">
                  <span class="post_header_day_time">
                  <img src="@/assets/front_component/images/assets/board01.png" alt="時間" />
                  <time class="en"> {{ this.latest_release_date }} </time> 更新
                </span>
                </div>
                <div class="t_c">
                  <img src="@/assets/front_component/images/assets/tab07_out.png">  
                </div>
                  <h1>アプリケーション更新情報</h1>
                  <div class="release_colomn">
                    <h3><b>v2.2.0 (2023.09.06)</b></h3>
                    <h4>機能追加</h4>
                    <dl>
                      <dt>承認/却下コメントの表示</dt>
                      <dd>承認、却下の際のコメントがアプリ上からも確認できるようになりました。</dd>
                      <dt>サンクスカード・リアクション機能</dt>
                      <dd>もらったサンクスカードに対してスタンプリアクションができるようになりました。</dd>
                      <dt>気づき日報未読表記についてのバグ修正</dt>
                      <dd>一部のユーザにて気づき日報を表示したにもかかわらず未読表示が消えないなどのバグが修正されました。</dd>                    
                    </dl>
                  </div>
                  <div class="release_colomn">
                    <h3><b>v2.1.0 (2023.07.26)</b></h3>
                    <h4>機能追加</h4>
                    <dl>
                      <dt>リリースノートページ追加</dt>
                      <dd>アプリケーションバージョンや機能追加についてお知らせするページが追加になりました。</dd>
                      <dt>承認依頼通知表示機能追加</dt>
                      <dd>トップページ上部、マイプロフィールページにて承認依頼の有無、件数を表示させる機能が追加されました。</dd>
                    </dl>
                    <h4>バグ修正/仕様変更</h4>
                    <dl>
                      <dt>エラーログ機能の補完</dt>
                      <dd>エラー発生時の情報がより詳細に取得できるように仕様が変更となっております。<br>
                      （アプリケーションの利用に影響はありません。）</dd>
                    </dl>
                  </div>
                  <div class="release_colomn">
                    <h3><b>v2.0.1 (2023.06.01)</b></h3>
                    <h4>バグ修正/仕様変更</h4>
                    <dl>
                      <dt>承認依頼の通知不具合の解消</dt>
                      <dd>一部のユーザで承認依頼が発生しているにも関わらず一覧に表示されない不具合が修正されました。</dd>
                      <dt>承認一覧ページの表示不具合の解消</dt>
                      <dd>最左列の'すべて'で表示される項目に設定不可の項目がありましたが、全て表示されるようになりました。</dd>
                    </dl>
                  </div>
                  <div class="release_colomn">
                    <h3><b>v2.0.0 (2023.06.01)</b></h3>
                    <h4>バグ修正/仕様変更</h4>
                    <dl>
                      <dt>アプリケーション全体のレスポンス改善</dt>
                      <dd>ページ表示にかかるまでの時間を短縮するためにプログラム改善を行いました。<br>
                      ページにより最大1.6倍の表示高速化となります。</dd>
                    </dl>
                  </div>  
                </article>
              <div class="t_c">
              <div class="btn_wide blue">
              <!-- <a href="javascript:history.back();">もどる</a> -->
              <router-link :to="{ name: 'Control Login' }">ログイン画面へもどる</router-link>
              </div>
              </div>
            </div>
            <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥"></div> -->
            <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥"></div>
          </div>
          <div class="content" v-else>
            <div class="innar">
              <article class="post_area">
                <div class="date t_r">
                  <span class="post_header_day_time">
                  <img src="@/assets/front_component/images/assets/board01.png" alt="時間" />
                  <time class="en"> {{ this.latest_release_date }} </time> 更新
                </span>
                </div>
                <div class="t_c">
                  <img src="@/assets/front_component/images/assets/tab07_out.png">  
                </div>
                  <h1>アプリケーション更新情報</h1>
                  <div class="release_colomn">
                    <h3><b>v2.2.0 (2023.09.06)</b></h3>
                    <h4>機能追加</h4>
                    <dl>
                      <dt>承認/却下コメントの表示</dt>
                      <dd>承認、却下の際のコメントがアプリ上からも確認できるようになりました。</dd>
                      <dt>サンクスカード・リアクション機能</dt>
                      <dd>もらったサンクスカードに対してスタンプリアクションができるようになりました。</dd>
                      <dt>気づき日報未読表記についてのバグ修正</dt>
                      <dd>一部のユーザにて気づき日報を表示したにもかかわらず未読表示が消えないなどのバグが修正されました。</dd>                    
                    </dl>
                  </div>
                  <div class="release_colomn">
                    <h3><b>v2.1.0 (2023.07.26)</b></h3>
                    <h4>機能追加</h4>
                    <dl>
                      <dt>リリースノートページ追加</dt>
                      <dd>アプリケーションバージョンや機能追加についてお知らせするページが追加になりました。</dd>
                      <dt>承認依頼通知表示機能追加</dt>
                      <dd>トップページ上部、マイプロフィールページにて承認依頼の有無、件数を表示させる機能が追加されました。</dd>
                    </dl>
                    <h4>バグ修正/仕様変更</h4>
                    <dl>
                      <dt>エラーログ機能の補完</dt>
                      <dd>エラー発生時の情報がより詳細に取得できるように仕様が変更となっております。<br>
                      （アプリケーションの利用に影響はありません。）</dd>
                    </dl>
                  </div>
                  <div class="release_colomn">
                    <h3><b>v2.0.1 (2023.06.01)</b></h3>
                    <h4>バグ修正/仕様変更</h4>
                    <dl>
                      <dt>承認依頼の通知不具合の解消</dt>
                      <dd>一部のユーザで承認依頼が発生しているにも関わらず一覧に表示されない不具合が修正されました。</dd>
                      <dt>承認一覧ページの表示不具合の解消</dt>
                      <dd>最左列の'すべて'で表示される項目に設定不可の項目がありましたが、全て表示されるようになりました。</dd>
                    </dl>
                  </div>
                  <div class="release_colomn">
                    <h3><b>v2.0.0 (2023.06.01)</b></h3>
                    <h4>バグ修正/仕様変更</h4>
                    <dl>
                      <dt>アプリケーション全体のレスポンス改善</dt>
                      <dd>ページ表示にかかるまでの時間を短縮するためにプログラム改善を行いました。<br>
                      ページにより最大1.6倍の表示高速化となります。</dd>
                    </dl>
                  </div>  
                </article>
              <div class="t_c">
              <div class="btn_wide blue">
              <!-- <a href="javascript:history.back();">もどる</a> -->
              <router-link :to="{ name: 'Control Login' }">ログイン画面へもどる</router-link>
              </div>
              </div>
            </div>
            <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥"></div> -->
            <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥"></div>
          </div>
        </div>
        <div class="kusa_outline">
          <div class="kusa_area">
            <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
            <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/kusa02.png" alt="草"></div>
            <div class="deco_wrap03"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
            <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
            <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
            <div class="deco_wrap06"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
            <div class="deco_wrap07"><img src="@/assets/front_component/images/assets/kusa05.png" alt="草"></div>
            <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
            <div class="deco_wrap09"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
            <div class="deco_wrap10"><img src="@/assets/front_component/images/assets/kusa07.png" alt="草"></div>
            <div class="deco_wrap11"><img src="@/assets/front_component/images/assets/kusa06.png" alt="草"></div>
            <div class="deco_wrap12"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
            <div class="deco_wrap13"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
            <div class="deco_wrap14"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
            <div class="deco_wrap15"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
            <div class="deco_wrap16"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
            <div class="deco_wrap17"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          </div>
        </div>
        <div class="deco_outline">
          <div class="deco_area">
            <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球"></div>
            <div class="deco_wrap02">
              <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
              <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
              <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
            </div>
            <div class="deco_wrap03">
              <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
              <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
              <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
            </div>
            <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター"></div>
            <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球"></div>
            <div class="deco_wrap06">
              <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
              <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
              <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
            </div>
            <div class="deco_wrap07">
              <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
              <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
              <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
            </div>
            <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球"></div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </template>
  
  <script>
  import $ from 'jquery';
  import Footer from "@/layout/front_panel/Footer.vue";
  import Header from "@/views/front_panel/header/Header.vue";


  export default {
    // name: "error",
    data() {
      return {
      phone: "",
      latest_release_date: process.env.VUE_APP_LATEST_RELEASE_DATE,
      };
    },
    components: {
      Footer,
      Header
    },
    created() {
    this.phoneMatch();
    },
    mounted() {
      document.body.className = 'page_template page_error';
      
      this.$nextTick(function() {
        $(function(){
          $('#js-loader').delay(300).fadeOut(600);
          $(window).scrollTop(0);
        });
      });
    },
    methods: {
      phoneMatch: function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        this.phone = true;
        document.body.id = "sp_style";
      } else {
        this.phone = false;
        document.body.id = "pc_style";
      }
    },
    },
  };
  </script>

<style scoped>
.release_colomn dt{
  margin-left: 1em;
  font-size: 115%;
  font-weight: 900;
  margin: 1em 0 .5em 0;
  border-bottom: 1px solid #bbb;
  display: inline-block;
  padding: 0 .2em 0 0;
}
.release_colomn dl{
  margin: -2em 0 4em 2.5em;
}
.release_colomn{
  border-bottom: 1px solid #ccc;
  margin-bottom: 6em;
}
h4 {
  margin: 0 0 0 1em;
}
</style>
