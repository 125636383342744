<template>
  <div
    id="g_side"
    class="g_side"
    v-bind:class="[isActive == 'isActive' ? 'sidebar-hidden' : '']"
  >
    <div
      class="circle_wrap tooltip tip-right"
      title="メニューの開閉"
      v-bind:class="[isActive == 'isActive' ? 'circle_wrap_hidden' : '']"
    >
      <a class="button-left-sidebar trigger-toggle-sidebar circle-btn"
        ><i class="fas fa-chevron-left fontawesome"></i
      ></a>
    </div>
    <a href="" class="logo_wrap"
      ><img src="@/assets/control_panel/images/logo_w.png" alt="ロゴ"
    /></a>
    <nav class="menulist">
      <ul id="sidenav">

        <li class="prt" :class="[$route.path.includes('/news') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'news' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="お知らせ">
              <i class="fas fa-info-circle"></i>
              <!-- <font-awesome-icon icon="home" size="lg" /> -->
            </div>
            <span class="menutxt">お知らせ</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/daily') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'daily' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="気づき日報">
              <i class="fas fa-file"></i>
            </div>
            <span class="menutxt">気づき日報</span>
          </router-link>
        </li>

        <!-- <li
          class="prt"
          :class="[$route.path.includes('/column') ? 'active' : '']"
        >
          <router-link to="/admin/column" dusk="event" active-class="active">
            <div class="menuicon tooltip tip-right" title="コラム">
              <i class="fas fa-columns"></i>
            </div>
            <span class="menutxt">コラム</span>
          </router-link>
        </li>

             <li
          class="prt"
          :class="[$route.path.includes('/sdgs') ? 'active' : '']"
        >
          <router-link to="/admin/sdgs" dusk="event" active-class="active">
            <div class="menuicon tooltip tip-right" title="SDGｓ個人目標 ">
              <i class="fas fa-bullseye"></i>
            </div>
            <span class="menutxt">SDGｓ個人目標 </span>
          </router-link>
        </li> -->

        <li class="prt" :class="[$route.path.includes('/event') ? 'active' : '']">
          <router-link to="/admin/event" dusk="event" active-class="active">
            <div class="menuicon tooltip tip-right" title="イベント ">
              <i class="fas fa-flag-checkered"></i>
            </div>
            <span class="menutxt">イベント </span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/thanks') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'thanks' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="サンクス">
              <i class="fas fa-handshake"></i>
            </div>
            <span class="menutxt">サンクス</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/summary') ? 'active' : '']">
          <router-link to="/admin/summary" active-class="active">
            <div class="menuicon tooltip tip-right" title="集計ページ">
              <i class="fas fa-handshake"></i>
            </div>
            <span class="menutxt">集計ページ</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/challenge') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'challenge' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="チャレンジ">
              <i class="fas fa-trophy"></i>
            </div>
            <span class="menutxt">チャレンジ</span>
          </router-link>
        </li>
        <li class="prt" :class="[$route.path.includes('/idea') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'idea' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="創発">
              <i class="fas fa-lightbulb"></i>
            </div>
            <span class="menutxt">創発</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/sdgs') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'sdgs' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="SDGs個人目標">
              <i class="fas fa-globe"></i>
            </div>
            <span class="menutxt">SDGs個人目標</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/column') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'column' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="コラム">
              <i class="fas fa-book"></i>
            </div>
            <span class="menutxt">コラム</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/photolog') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'photolog' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="フォトログ">
              <i class="fas fa-camera"></i>
            </div>
            <span class="menutxt">フォトログ</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/award') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin message', params: { type: 'award' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="表彰">
              <i class="fas fa-crown"></i>
            </div>
            <span class="menutxt">表彰</span>
          </router-link>
        </li>

        <li class="prt" :class="[$route.path.includes('/board') ? 'active' : '']">
          <router-link
            :to="{ name: 'Admin board archive', params: { type: 'board' } }"
            dusk="event"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="掲示板">
              <i class="far fa-clipboard"></i>
            </div>
            <span class="menutxt">掲示板</span>
          </router-link>
        </li>
        <!-- <li class="prt">
          <router-link to="/admin/event" dusk="event">
            <div class="menuicon tooltip tip-right" title="イベント">
              <i class="fas fa-flag-checkered"></i>
            </div>
            <span class="menutxt">イベント</span>
          </router-link>
        </li> -->

        <li class="prt" :class="[$route.path.includes('/calendar') ? 'active' : '']">
          <router-link to="/admin/calendar" dusk="calendar">
            <div class="menuicon tooltip tip-right" title="カレンダー">
              <i class="fas fa-calendar-alt"></i>
            </div>
            <span class="menutxt">カレンダー</span>
          </router-link>
        </li>
        <li
          class="prt"
          :class="[$route.path.includes('/ceo') ? 'active' : '']"
        >
          <router-link
            :to="{ name: 'Page Show', params: { type: 'ceo' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="社長メッセージ">
              <i class="fas fa-info-circle"></i>
              <!-- <font-awesome-icon icon="home" size="lg" /> -->
            </div>
            <span class="menutxt">社長メッセージ</span>
          </router-link>
        </li>

        <li
          class="prt"
          :class="[$route.path.includes('/business') ? 'active' : '']"
        >
          <router-link
            :to="{ name: 'Page Show', params: { type: 'business' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="社是">
              <i class="fas fa-info-circle"></i>
              <!-- <font-awesome-icon icon="home" size="lg" /> -->
            </div>
            <span class="menutxt">社是</span>
          </router-link>
        </li>

        <li
          class="prt"
          :class="[$route.path.includes('/policy') ? 'active' : '']"
        >
          <router-link
            :to="{ name: 'Page Policy Show', params: { type: 'policy' } }"
            active-class="active"
          >
            <div class="menuicon tooltip tip-right" title="会社・部門方針">
              <i class="fas fa-info-circle"></i>
              <!-- <font-awesome-icon icon="home" size="lg" /> -->
            </div>
            <span class="menutxt">会社・部門方針</span>
          </router-link>
        </li>

        <!-- <li class="prt">
          <router-link to="/jigyosho" dusk="jigyosho_joho">
            <div class="menuicon tooltip tip-right" title="施設情報">
              <i class="fas fa-map-marker-alt fontawesome"></i>
            </div>
            <span class="menutxt">施設情報</span>
          </router-link>
        </li> -->

        <li class="prt" :class="[$route.path.includes('/user') ? 'active' : '']">
          <router-link to="/admin/user" dusk="shisetsu_sutaffu">
            <div class="menuicon tooltip tip-right" title="ユーザー">
              <i class="fas fa-user fontawesome"></i>
            </div>
            <span class="menutxt">ユーザー</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
  <div id="g_side_except" class="side_open_except"></div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";

export default {
  name: "Sidebar",
  data: () => ({
    isActive: false,
  }),
  components: {},
  mounted() {
    this.isActive = getCookie("myCookieName");

    if (this.isActive == "isActive") {
      $("body").addClass("sidebar-hidden");
    } else {
      $("body").removeClass("sidebar-hidden");
    }

    var flg = "off";
    function LeftSidebar() {
      $("#g_side").toggleClass("sidebar-hidden");
      //$("#g_side").hasClass("sidebar-hidden");

      $("body").toggleClass("sidebar-hidden");
      // $("body").hasClass("sidebar-hidden");
      $(".circle_wrap").toggleClass("circle_wrap_hidden");
    }

    function setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
    function eraseCookie(name) {
      document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    $(".trigger-toggle-sidebar, .side_open_except").on("click", function () {
      LeftSidebar();

      if ($("#g_side").hasClass("sidebar-hidden")) {
        let w_size = $(window).width();
        let w_g_side_size = $("#g_side").outerWidth();
        $("#g_side_except").show().css({
          width: w_size,
        });
        $("#g_header .side_open_except").show();
      } else {
        $("#g_side_except").hide();
        $("#g_header .side_open_except").hide();
      }

      if (flg == "off") {
        flg = "on";
      } else {
        flg = "off";
      }
      // setMyCookie();
      // getCookie("myCookieName");
      var myCookieVal = $("#g_side,body").hasClass("sidebar-hidden")
        ? "isActive"
        : "notActive";
      setCookie("myCookieName", myCookieVal, 10);

      // console.log(getCookie("myCookieName"));
    });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
