import axios from "axios";

const END_POINT = ''
// const END_POINT = 'api/nonauth/'


let Api = function () {

    return axios.create({
        baseURL: process.env.VUE_APP_ROOT_API + `${END_POINT}`,
        header :{
            'X-Requested-With' : 'XMLHttpRequest',
            "Content-type": "application/json; multipart/form-data",
        },
        withCredentials: true,
    });

}

export default Api;
